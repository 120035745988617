export const sortedPages = casePages => {
  const sortedPagesInOrder = casePages.slice().sort((prevPage, nextPage) => {
    const prevSlug = Number(prevPage.casePage.slug)
    const nextSlug = Number(nextPage.casePage.slug)

    if (prevSlug > nextSlug) {
      return -1
    }
    if (prevSlug < nextSlug) {
      return 1
    }
  })

  const sortedPagesByCardSize = sortedPagesInOrder.slice().sort((prevPage, nextPage) => {
    const cardSizePriority: { [key: string]: 1 | 0 } = {
      large: 1,
      small: 0,
    }

    const prevCardSizeWeight = cardSizePriority[prevPage.casePage.frontmatter.cardSize]
    const nextCardSizeWeight = cardSizePriority[nextPage.casePage.frontmatter.cardSize]

    if (prevCardSizeWeight > nextCardSizeWeight) {
      return -1
    }
    if (prevCardSizeWeight < nextCardSizeWeight) {
      return 1
    }
  })

  return {
    sortedPagesByCardSize,
  }
}
