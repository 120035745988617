import { VFC } from 'react'
import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { CaseCardProps } from '@/components/types'
import { colorConfig, caseColor, mixins } from '@/utils/styleConfig'
import { breakpointUp, breakpointDown } from '@/utils/breakpoints'

export const CaseCard: VFC<CaseCardProps> = ({ link, heading, imgSrc, caseType, cardSize }) => (
  <StyledLink to={link}>
    <RoundedCornersContainer>
      <Card cardSize={cardSize} caseType={caseType}>
        <RoundedCornersContainer>
          <ImgContainer cardSize={cardSize}>
            <img src={imgSrc} alt="" width="468" height="324" />
          </ImgContainer>
        </RoundedCornersContainer>
        <CardBody>
          <Heading cardSize={cardSize}>{heading}</Heading>
        </CardBody>
      </Card>
    </RoundedCornersContainer>
  </StyledLink>
)

const StyledLink = styled(Link)`
  ${mixins.hoverButton};
  display: block;
  border-radius: 1rem;
`

const Card = styled.div<Partial<CaseCardProps>>`
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: ${({ cardSize }) =>
    cardSize === 'small' ? '1.2rem 1.2rem 0 1.2rem' : '1.6rem 1.6rem 0 1.6rem'};
  background: linear-gradient(
    135deg,
    ${({ caseType }) => caseColor[caseType].gradation1},
    ${({ caseType }) => caseColor[caseType].gradation2}
  );
  height: ${({ cardSize }) => (cardSize === 'small' ? '14rem' : '25rem')};

  ${breakpointUp('md')} {
    padding: ${mixins.vw({ size: 16 })} ${mixins.vw({ size: 16 })} 0 ${mixins.vw({ size: 16 })};
    height: ${({ cardSize }) =>
      cardSize === 'small' ? mixins.vw({ size: 187 }) : mixins.vw({ size: 398 })};
  }

  ${breakpointUp('lg')} {
    padding: 1.6rem 1.6rem 0 1.6rem;
    height: ${({ cardSize }) => (cardSize === 'small' ? '18.7rem' : '39.8rem')};
  }
`

const CardBody = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const Heading = styled.p<Partial<CaseCardProps>>`
  ${mixins.lineClamp(2)};
  font-weight: bold;
  line-height: 1.2;
  font-size: ${({ cardSize }) => (cardSize === 'small' ? '1.2rem' : '1.6rem')};
`

const ImgContainer = styled.div<Partial<CaseCardProps>>`
  width: 100%;

  ${breakpointUp('md')} {
    ${({ cardSize }) =>
      cardSize === 'small'
        ? css`
            height: ${mixins.vw({ size: 111 })};
          `
        : css`
            height: ${mixins.vw({ size: 324 })};
          `}
  }

  ${breakpointUp('lg')} {
    ${({ cardSize }) =>
      cardSize === 'small'
        ? css`
            height: 11.1rem;
          `
        : css`
            height: 32.4rem;
          `}
  }

  img {
    object-fit: cover;
    height: 100%;
  }
`
