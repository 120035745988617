// feature
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '@/store/index'
import { Seo, LayoutContainer } from '@/utils/globalComponents'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { sortedPages } from '@/feature/sortedPages'
import { metaData } from '@/feature/staticData'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'

// interface
import { ChangeEvent } from 'react'

// components
import Layout from '@/components/Layout'
import { CaseCard } from '@/components/CaseCard'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { InputButton } from '@/components/InputButton'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { AnchorAdjuster, BreakPointHiddenBr } from '@/components/Utils'

export const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      casePages: edges {
        casePage: node {
          id
          slug
          frontmatter {
            cardSize
            caseTags
            caseType
            date(formatString: "YYYY.MM.DD")
            title
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { casePages } = props.data.allMdx
  const { sortedPagesByCardSize } = sortedPages(casePages)

  const selectedLargeImageCase = sortedPagesByCardSize.filter(
    item => item.casePage.frontmatter.cardSize === 'large',
  )

  // OPTIMIZE: 最大表示数の判定をもう少しすっきりさせたい
  let caseCount = 0

  const isShowCase = (articleCaseTags: string[]): boolean => {
    const selectedLargeImageCaseLength = selectedLargeImageCase.length

    // NOTE: 最大表示数を 4 とする
    if (caseCount >= 4) {
      return false
    }

    for (let index = 0; index < selectedLargeImageCaseLength; index++) {
      const hasTag = articleCaseTags.includes('ディスプレイ事業部')

      if (!hasTag) {
        return false
      }
    }

    caseCount++
    return true
  }

  const routingSelectedCaseTags = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked: boolean = event.currentTarget.checked

    props.clearCaseFilter()

    if (isChecked) {
      props.addCaseFilter(event)
    }

    navigate('/case/')
  }

  return (
    <Layout>
      <Seo page="display-detail" />
      <DisplayDetail>
        <LayoutContainer>
          <HeadingLevel1 subHeading="ディスプレイ事業部" note="flow">
            製品が出来る
            <BreakPointHiddenBr direction="up" breakpoint="md" />
            までの流れ
          </HeadingLevel1>
          <Flow>
            <FlowOrderedList>
              <li>
                <AnchorAdjuster id="flow-1"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_light-bulb.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">企画 / 立案・設計</HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/display/detail/img_01.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      ミュージアムに常設される大型展示物からイベントで使用される小さな装置にいたるまで、すべては企画・立案からスタートします。その展示で何を伝えたいか、どういう効果を期待しているかなど、まずお客様のご要望をヒアリング。それを実現するための豊富な手法や技術を組み合わせながら、お客様が想定されている以上のプランを提案いたします。
                    </p>
                  </FlowTextInner>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-2"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_hearing.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">
                    造作・什器・造形・
                    <BreakPointHiddenBr direction="up" breakpoint="md" />
                    模型 /設計・製作
                  </HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/display/detail/img_02.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      多くの展示において、造作什器・造形模型やジオラマなど立体的な造作造形物が必要になります。当社は創業130年の豊富な経験からさまざまな技術とノウハウを蓄積しており、お客様が求められる造形物に関して、素材や製作方法など多様な選択肢から最適のものをコーディネイト。展示効果が高く、コストパフォーマンスに優れ、納期に対応した最適の模型や造形物を提案します。必要に応じてパースや3D画像など、お客様に納得していただけるビジュアル手法でご説明いたします。
                    </p>
                  </FlowTextInner>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-3"></AnchorAdjuster>
                <FlowIcon>
                  <img
                    src="/images/service/icon_screw-driver.svg"
                    alt=""
                    width="128"
                    height="128"
                  />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">メカ / 設計・製作</HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/display/detail/img_03.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      観覧者の興味を惹きつけたり、エンターテインメントとしての機能を果たすために、展示装置にはさまざまな動きが求められます。そうしたメカニズムも自社の専門スタッフが設計・製作します。当社には機械や木型、教育用理化学機器の製作会社として創業した豊富な経験があります。その知識とノウハウを生かして、展示効果、イニシャルからランニングまでのコスト、さらには観覧者の安全面にいたるまで計算して提案いたしますので、安心してお任せいただけます。
                    </p>
                  </FlowTextInner>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-4"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_monitor.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">
                    制御ソフト / <BreakPointHiddenBr direction="up" breakpoint="md" />
                    設計・製作
                  </HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/display/detail/img_04.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      展示装置は電気や油圧などによって作動しますが、それを制御するのはPLCやマイコンなどのソフトです。この分野における当社の強みは、情報機器部門での実績。その豊富な経験を生かし、メカニズムの設計・製作スタッフと一体となって制御ソフトを構築しています。インターロックや保護回路など安全設計も万全です。また、スライドやムービーなどコンテンツの企画・編集も手がけており、動く装置と映像が一体となった表現力豊かな展示物をお届けいたします。
                    </p>
                  </FlowTextInner>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-5"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_wrench.svg" alt="" width="128" height="128" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">施工・メンテナンス</HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/display/detail/img_05.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      展示装置は電気や油圧などによって作動しますが、それを制御するのはPLCやマイコンなどのソフトです。この分野における当社の強みは、情報機器部門での実績。その豊富な経験を生かし、メカニズムの設計・製作スタッフと一体となって制御ソフトを構築しています。インターロックや保護回路など安全設計も万全です。また、スライドやムービーなどコンテンツの企画・編集も手がけており、動く装置と映像が一体となった表現力豊かな展示物をお届けいたします。
                    </p>
                  </FlowTextInner>
                </FlowTextContainer>
              </li>
            </FlowOrderedList>
          </Flow>
        </LayoutContainer>
        <LayoutContainer>
          <Case>
            <HeadingLevel2 note="works">情報機器事業部の事例</HeadingLevel2>
            <CaseCardList>
              {selectedLargeImageCase.map(({ casePage }) => {
                return (
                  isShowCase(casePage.frontmatter.caseTags) && (
                    <CaseCardListItem cardSize={casePage.frontmatter.cardSize} key={casePage.id}>
                      <CaseCard
                        link={`/case/${casePage.slug}/`}
                        heading={casePage.frontmatter.title}
                        imgSrc={`/images/case/${casePage.slug}/1.jpg`}
                        caseType={casePage.frontmatter.caseType}
                        cardSize={casePage.frontmatter.cardSize}
                      />
                    </CaseCardListItem>
                  )
                )
              })}
            </CaseCardList>
            <InputButtonContainer>
              <InputButton
                onChange={event => routingSelectedCaseTags(event)}
                value={metaData.display.title}
                checked={props.selectedCaseTags.includes(metaData.display.title)}
                label={`${metaData.display.title}の事例はこちら`}
                color="theme"
              ></InputButton>
            </InputButtonContainer>
          </Case>
        </LayoutContainer>
      </DisplayDetail>
    </Layout>
  )
}

const DisplayDetail = styled.div``

const Flow = styled.section`
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: 0;
    right: 0;
    width: 57.7rem;
    height: 64rem;
  }
`

const FlowOrderedList = styled.ol`
  ${breakpointDown('sm')} {
    margin-top: 4.8rem;
  }

  ${breakpointUp('md')} {
    margin-top: 11rem;
  }

  > li {
    ${breakpointUp('md')} {
      display: flex;
      position: relative;

      &:not(:last-of-type) {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 14.4rem;
          left: 9.2rem;
          width: 0.2rem;
          height: calc(100% - 7rem);
          background-color: ${colorConfig.borderColor};
        }
      }
    }

    + li {
      ${breakpointDown('sm')} {
        margin-top: 5.6rem;
      }

      ${breakpointUp('md')} {
        margin-top: 10rem;
      }
    }
  }
`

const FlowIcon = styled.div`
  ${breakpointDown('sm')} {
    width: 11.8rem;
    margin-left: -1.4rem;
  }

  ${breakpointUp('md')} {
    flex-basis: 18.6rem;
    flex-shrink: 0;
    margin-top: -3.6rem;
    margin-right: 7rem;
  }
`

const FlowTextContainer = styled.div`
  width: 100%;

  ${breakpointDown('sm')} {
    margin-top: -1rem;
  }
`

const FlowImgContainer = styled.div`
  ${breakpointDown('sm')} {
    margin-bottom: 2rem;
  }

  ${breakpointUp('md')} {
    float: right;
    flex-shrink: 0;
    margin-left: 2rem;
    width: 25.4rem;
  }
`

const FlowTextInner = styled.div`
  ${breakpointDown('sm')} {
    margin-top: 2rem;
    margin-bottom: 2.8rem;
  }

  ${breakpointUp('md')} {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 4rem;
    margin-bottom: 4.8rem;
  }
`

const Case = styled.section`
  margin: 8rem 0;

  ${breakpointUp('md')} {
    margin: 10rem 0;
  }
`

const CaseCardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem;
  margin-top: 4rem;

  ${breakpointUp('md')} {
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    gap: ${mixins.vw({ size: 36 })} ${mixins.vw({ size: 24 })};
  }

  ${breakpointUp('lg')} {
    gap: 3.6rem 2.4rem;
  }
`

const CaseCardListItem = styled.li<{ cardSize: string }>`
  ${({ cardSize }) =>
    cardSize === 'large'
      ? css`
          grid-row: span 2;
          grid-column: span 2;
        `
      : css`
          width: 100%;
        `}
`

const InputButtonContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
`

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
