import { VFC } from 'react'
import styled from 'styled-components'
import { RoundedCornersContainerProps } from '@/components/types'

export const RoundedCornersContainer: VFC<RoundedCornersContainerProps> = ({ children }) => (
  <StyledRoundedCornersContainer>{children}</StyledRoundedCornersContainer>
)

const StyledRoundedCornersContainer = styled.div`
  overflow: hidden;
  border-radius: 1rem;
  width: 100%;
`
