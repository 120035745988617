import { VFC } from 'react'
import styled from 'styled-components'
import { HeadingLevel2Props } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'

export const HeadingLevel2: VFC<HeadingLevel2Props> = ({ children, note, color, align }) => (
  <StyledHeadingLevel2 align={align}>
    <Heading color={color}>{children}</Heading>
    {note && <Note>{note}</Note>}
  </StyledHeadingLevel2>
)

HeadingLevel2.defaultProps = {
  note: '',
  color: 'main',
  align: 'start',
}

const StyledHeadingLevel2 = styled.div<Partial<HeadingLevel2Props>>`
  display: inline-flex;
  flex-direction: column;

  ${breakpointDown('sm')} {
    align-items: ${({ align }) =>
      align === 'start'
        ? 'flex-start'
        : align === 'center'
        ? 'center'
        : align === 'end'
        ? 'flex-end'
        : ''};
  }
`

const Heading = styled.h2`
  display: inline-block;
  font-size: 2.8rem;
  color: ${({ color }) => (color === 'theme' ? colorConfig.themeColor : colorConfig.textMainColor)};
  font-weight: bold;
  line-height: 1.5;

  ${breakpointUp('md')} {
    font-size: 3.2rem;
  }
`

const Note = styled.span`
  font-size: 1rem;
  line-height: 1.5;
  color: ${colorConfig.themeColor};
  text-transform: uppercase;

  ${breakpointDown('sm')} {
    margin-top: 0.8rem;
  }

  ${breakpointUp('md')} {
    font-size: 1.4rem;
  }
`
