import { VFC } from 'react'
import styled, { css } from 'styled-components'
import { BaseButtonStyle } from '@/components/CommonButton'
import { InputButtonProps } from '@/components/types'

export const InputButton: VFC<InputButtonProps> = ({
  label,
  color,
  outline,
  value,
  checked,
  onChange,
  size,
}) => (
  <Label>
    <BaseButtonStyle color={color} size={size} outline={outline}>
      {label}
      <input onChange={onChange} type="checkbox" value={value} checked={checked} />
    </BaseButtonStyle>
  </Label>
)

InputButton.defaultProps = {
  size: 'stretch',
  color: 'theme',
  outline: false,
}

const Label = styled.label`
  cursor: pointer;

  input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`
